.pranati-container {
  max-width: 1223px;
  margin: auto;
  margin-top: 175px;
  margin-bottom: 112px;
  padding: 0px 23px;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #333333;
  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 32px;
  }
  img {
    width: 100%;
    height: auto;
  }
  ul {
    margin: 12px 0px 50px 0px;
    li {
      line-height: 200%;
    }
  }
  .objective {
    background: #f4f4f4;
    padding: 38px 60px;
  }
  .analysis-container {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    .analysis-item {
      width: 48%;
      display: flex;
      margin-top: 80px;
    }
    img {
      width: 72px;
      height: 74px;
      margin-right: 34px;
    }
    .percent {
      font-weight: 700;
      font-size: 124px;
      font-family: "Poppins";
      margin-top: 40px;
      margin-right: 24px;
    }
  }
  .info-container {
    display: flex;
    gap: 10%;
    margin-top: 113px;
    .desc {
      max-width: 715px;
    }
    img {
      width: 371px;
      height: 137px;
      margin-top: 110px;
    }
  }
  .info-container-2 {
    display: flex;
    gap: 3%;
    .desc {
      max-width: 715px;
    }
    img {
      width: 590px;
      height: 445px;
      margin-top: -75px;
    }
  }
  .analysis-table {
    img {
      margin-right: 39px;
      margin-bottom: -6.5px;
    }
    .amaha-img {
      width: 81px;
      height: 29px;
    }
    .docvita-img {
      width: 125px;
      height: 29px;
    }
    span {
      font-weight: 700;
      color: #e76943;
    }
    .table-wrapper {
      overflow-x: auto;
      padding-bottom: 15px;
      table {
        border-collapse: collapse;
        width: 1223px;
      }

      th {
        border-bottom: 1px solid #c4c4c4;
        text-align: left;
        padding: 8px;
      }

      td {
        padding: 8px;
        text-align: left;
        vertical-align: top;
        max-width: 280px;
      }
    }
  }
  .analysis-result-container {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-top: 40px;
    .analysis-result-item {
      width: 48%;
      display: flex;
    }
    img {
      width: 54px;
      height: 54px;
      margin-right: 27px;
    }
  }
  .challenges {
    display: flex;
    gap: 31px;
    ul {
      padding-left: 30px;
      li {
        line-height: 150%;
      }
    }
    .desc-wrapper {
      display: flex;
      flex-direction: column;
      img {
        max-width: 592px;
        margin-inline: auto;
      }
      .desc {
        background: #fee4dc;
        border-radius: 8px;
        padding: 16px 32px;
      }
    }
  }
  .ui-palette-wrapper {
    display: flex;
    gap: 32px;
    img {
      max-width: 280px;
    }
    .palette {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      h4 {
        font-weight: 400;
        margin-bottom: 21px;
        color: #7d7d7d;
        font-size: 24px;
      }
      .colors {
        display: flex;
        gap: 32px;
        flex-wrap: wrap;
        .color {
          width: 119px;
          height: 119px;
        }
        .one {
          background: #8ecbc4;
        }
        .two {
          background: #ececec;
        }
        .three {
          background: #4b8d8d;
        }
      }
    }
  }
  .homepage {
    display: flex;
    gap: 85px;
    margin-bottom: 50px;
    img {
      max-width: 592px;
    }
  }
  .other-pages {
    display: flex;
    gap: 24px;
    .pages-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
  .nav-buttons {
    display: flex;
    justify-content: end;
    img {
      width: 24px;
      height: 24px;
      margin-bottom: -4px;
      padding-top: 8px;
    }
    a {
      color: #1175b5;
      font-weight: 700;
      text-decoration: none;
      float: right;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .pranati-container {
    font-size: 14px;
    margin-top: 118px;
    margin-bottom: 70px;
    h2 {
      font-size: 20px;
      line-height: 28px;
    }
    ul {
      li {
        line-height: 180%;
      }
    }
    .objective {
      padding: 23px 32px;
    }
    .analysis-container {
      gap: 0px;
      .analysis-item {
        width: 100%;
        margin-top: 45px;
        img {
          width: 50px;
          height: 53px;
        }
      }
      .percent {
        font-size: 75px;
        margin-top: 22px;
      }
    }
    .info-container {
      flex-direction: column;
      flex-flow: column-reverse;
      margin-top: 70px;
      .desc {
        max-width: unset;
      }
      img {
        width: 100%;
        height: auto;
        margin-top: unset;
        margin-bottom: 32px;
        margin-inline: auto;
        max-width: 70%;
      }
    }
    .info-container-2 {
      flex-direction: column;
      flex-flow: column-reverse;
      .desc {
        max-width: unset;
      }
      img {
        width: 100%;
        height: auto;
        margin-top: unset;
        margin-bottom: 32px;
        margin-inline: auto;
        max-width: 70%;
      }
    }
    .analysis-result-container {
      img {
        width: 42px;
        height: 42px;
        margin-right: 16px;
      }
      .analysis-result-item {
        width: 100%;
      }
    }
    .challenges {
      flex-direction: column;
      gap: 0px;
      margin-bottom: 62px;
    }
    .ui-palette-wrapper {
      flex-direction: column;
      gap: 12px;
      .palette {
        h4 {
          font-size: 16px;
        }
      }
    }
    .homepage {
      flex-direction: column;
      gap: 18px;
      margin-bottom: 8px;
      img {
        max-width: unset;
      }
    }
    .other-pages {
      flex-direction: column;
    }
    .nav-buttons {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
