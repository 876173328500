.projects-container {
  padding: 120px 23px 0px 23px;
  margin-top: 30px;
  min-width: 1223px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  line-height: 150%;
  .projects-heading {
    display: flex;
    list-style: none;
    padding: unset;
    border-bottom: 1px solid #c4c4c4;
    .tab-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 32px;
      height: 92px;
      font-weight: 400;
      font-size: 24px;
      color: #7d7d7d;
      cursor: pointer;
    }
    .active {
      font-weight: 700;
      color: #333333;
      border-bottom: 6px solid #F6DCBC;
    }
  }
  .projects-list-wrapper {
    margin-top: 20px;
    display: flex;
  }
}

@media only screen and (max-width: 1420px) {
  .projects-container {
    min-width: unset;
    padding-top: 65px;
    gap: 0px;
    .projects-heading {
      .tab-item {
        font-size: 16px;
        text-align: center;
        height: 66px;
      }
    }
    .projects-list-wrapper {
      margin-top: 35px;
    }
  }
}
