.landing-section {
  background: url("../../../assets/home-background.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  object-fit: cover;
  padding: 0px 23px;
  .content-wrapper {
    max-width: 1223px;
    margin: auto;
    .details-container {
      text-align: center;
      margin-top: 189px;
      .line1 {
        max-width: 770px;
        margin: auto;
        font-weight: 400;
        font-size: 70px;
        font-family: "Dancing Script";
        margin-bottom: 40px;
      }
      .line2 {
        max-width: 1200px;
        margin: auto;
        font-weight: 400;
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 97px;
      }
      .work-button {
        font-weight: 700;
        font-size: 18px;
        padding: 20px 60px;
        border: 2px solid #000000;
        border-radius: 8px;
        background-color: unset;
        height: fit-content;
        color: #333333;
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .landing-section {
    background: none;
    .content-wrapper {
      .details-container {
        margin-top: unset;
        .line1 {
          font-size: 40px;
          margin-bottom: 30px;
        }
        .line2 {
          font-size: 20px;
          line-height: 150%;
          margin-bottom: 40px;
        }
        .work-button {
          max-width: 228px;
          padding: 12px 29px;
          font-size: 16px;
        }
      }
    }
  }
}
