.contact-container {
  max-width: 1223px;
  padding: 0px 23px;
  min-width: 1223px;
  margin: 0px auto 125px;
  padding-top: 70px;
  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 70px;
    .description {
      font-size: 18px;
      line-height: 150%;
      max-width: 49%;
    }
    .info-container {
      background: #f9f9f9;
      display: flex;
      flex-direction: column;
      padding: 36px 0px 24px 40px;
      min-width: 42%;
      .email,
      .behance {
        margin-bottom: 13px;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 22px;
        margin-bottom: -5px;
      }
      a{
        text-decoration: underline;
        cursor: pointer;
      }
      span,
      a {
        font-weight: 700;
        font-size: 14px;
        color: #333333;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .contact-container {
    min-width: unset;
    margin-bottom: 16px;
    padding-top: 70px;
    h2{
      font-size: 20px;
      margin-top: unset;
    }
    .content-wrapper {
      gap: 24px;
      .description {
        max-width: unset;
        font-size: 16px;
      }
      .info-container {
        width: 100%;
      }
    }
  }
}
