.about-page {
  max-width: 1223px;
  margin: auto;
  margin-top: 180px;
  margin-bottom: 112px;
  position: relative;
  padding: 0px 23px;
  .heading {
    font-weight: 700;
    font-size: 48px;
    color: #333333;
    position: relative;
    &::after {
      background: #F6DCBC;
      content: "";
      height: 11px;
      left: 0;
      margin-top: 70px;
      position: absolute;
      width: 104px;
    }
  }
  .image {
    width: 100%;
    height: auto;
    margin: 32px 0px;
  }
  .accordion {
    border-radius: 2px;
    color: #333333;
  }
  .accordion-item {
    border-bottom: 2px solid #eeeeee;
  }
  .accordion-button {
    color: #231f20;
    cursor: pointer;
    padding: 40px 18px 40px 12px;
    text-align: left;
    border: none;
    display: flex;
    font-weight: 700;
    font-size: 24px;
    &::after {
      display: inline-block;
      content: "";
      position: absolute;
      height: 18px;
      width: 18px;
      right: 20px;
      border-bottom: 2px solid #333333;
      border-right: 2px solid #333333;
      transform: rotate(45deg);
    }
  }
  .accordion-button[aria-expanded="true"]::after,
  .accordion-button[aria-selected="true"]::after {
    transform: rotate(-135deg);
  }
  .accordion-panel {
    animation: fadein 0.35s ease-in;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    .list {
      list-style: none;
      margin: unset;
      padding: unset;
      margin-left: 12px;
      .list-item {
        display: flex;
        margin-bottom: 38px;
        .range {
          min-width: 200px;
          margin-right: 40px;
        }
        .edu-range {
          min-width: 200px;
          margin-right: 191px;
        }
        .right-section {
          .title {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 26px;
          }
          .school {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 16px;
          }
          .desc {
            letter-spacing: -0.2px;
          }
        }
      }
    }
    .info-container {
      display: flex;
      flex-direction: column;
      padding: 0px 0px 24px 40px;
      min-width: 42%;
      .email,
      .behance {
        margin-bottom: 13px;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 22px;
        margin-bottom: -5px;
      }
      a {
        text-decoration: underline;
        cursor: pointer;
      }
      span,
      a {
        font-weight: 400;
        font-size: 18px;
        color: #333333;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .about-page {
    margin-top: 96px;
    .heading {
      font-size: 24px;
      &::after {
        height: 6px;
        margin-top: 37px;
        width: 52px;
      }
    }
    .image {
      min-width: unset;
    }
    .accordion {
      .accordion-button {
        font-size: 16px;
        padding-block: 22px;
        padding-left: 6px;
        &::after {
          height: 10px;
          width: 10px;
        }
      }
      .accordion-panel {
        font-size: 14px;
        .panel-image {
          min-width: unset;
          min-height: 77px;
        }
        .list {
          .list-item {
            flex-direction: column;
            .right-section {
              .title {
                font-size: 14px;
                margin-bottom: 16px;
              }
              .school {
                font-size: 14px;
                margin-bottom: 10px;
              }
            }
          }
        }
        .info-container {
          span,
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
}
