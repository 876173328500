.tag-container {
  max-width: 1223px;
  margin: auto;
  margin-top: 175px;
  margin-bottom: 112px;
  padding: 0px 23px;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #333333;
  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 32px;
  }
  img {
    width: 100%;
    height: auto;
  }
  ul {
    margin: 12px 0px 50px 0px;
    li {
      line-height: 200%;
    }
  }
  .objective {
    background: #f4f4f4;
    padding: 38px 60px;
  }
  .insights {
    display: flex;
    gap: 10%;
    .desc {
      max-width: 715px;
    }
    img {
      width: 428px;
      height: 257px;
      margin-top: 28px;
    }
  }
  .wireframe {
    display: flex;
    gap: 10px;
    img {
      max-width: 827px;
    }
    .desc {
      background: #fee4dc;
      border-radius: 8px;
      text-align: center;
      img {
        max-width: 105.11px;
        height: 94.27px;
        margin-top: 140px;
      }
      div {
        padding: 177px 21px 49px 28px;
        text-align: left;
      }
    }
  }
  .info-container {
    background: #dcf0fe;
    border-radius: 8px;
    padding: 24px 236px;
    text-align: center;
  }
  .screen-wrapper {
    padding: unset;
    .screen-item {
      list-style: none;
      display: flex;
      gap: 32px;
      margin-bottom: 40px;
      line-height: unset;
      .screen-img {
        max-width: 800px;
      }
      .desc {
        .index {
          width: 46px;
          height: 46px;
          background: #f4f4f4;
          border-radius: 8px;
          text-align: center;
          font-weight: 700;
          font-size: 20px;
          line-height: 230%;
          color: #7d7d7d;
          margin-bottom: 24px;
        }
      }
    }
  }
  .nav-buttons {
    display: flex;
    justify-content: space-between;
    img {
      width: 24px;
      height: 24px;
      margin-bottom: -4px;
      padding-top: 8px;
    }
    a {
      color: #1175b5;
      font-weight: 700;
      text-decoration: none;
    }
    .right-nav {
      float: right;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .tag-container {
    font-size: 14px;
    margin-top: 118px;
    margin-bottom: 70px;
    h2 {
      font-size: 20px;
      line-height: 28px;
    }
    ul {
      li {
        line-height: 180%;
      }
    }
    .objective {
      padding: 23px 32px;
    }
    .insights {
      flex-direction: column;
      flex-flow: column-reverse;
      .desc {
        max-width: unset;
      }
      img {
        width: 100%;
        height: auto;
        margin-top: unset;
        max-width: 70%;
        margin: auto;
      }
    }
    .wireframe {
      flex-direction: column;
      gap: 32px;
      img {
        max-width: unset;
      }
      .desc {
        display: flex;
        img {
          margin: auto;
          margin-left: 20px;
          width: 70px;
          height: 70px;
        }
        div {
          padding: 30px;
        }
      }
    }
    .info-container {
      padding: 24px;
    }
    .screen-wrapper {
      .screen-item {
        flex-direction: column;
        flex-flow: column-reverse;
        .screen-img {
          max-width: unset;
        }
      }
    }
    .nav-buttons {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
