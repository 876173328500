.client-project-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 45px;
  position: relative;
  padding-top: 30px;
  .image-wrapper {
    overflow: hidden;
    min-width: 1223px;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    a {
      display: flex;
    }
    .project1,
    .project2,
    .project3 {
      max-width: 1223px;
      width: 100%;
      height: auto;
      transition: transform 0.45s ease-out;
      cursor: pointer;
      &:hover {
        transform: scale(1.03);
        transition: transform 0.45s ease-out;
      }
    }
  }
  .background-img {
    position: absolute;
    width: 190px;
    height: 190px;
    right: -85px;
    top: -94px;
    z-index: -1;
  }
}

@media only screen and (max-width: 1420px) {
  .client-project-container {
    gap: 30px;
    .image-wrapper {
      min-width: unset;
    }
    .background-img{
      display: none;
    }
  }
}
