.other-projects-container {
  max-width: 1223px;
  padding: 0px 23px;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  h2 {
    margin: 164px 0px 100px;
  }
  .projects-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .project {
      max-width: 592px;
      width: 100%;
      height: auto;
      margin-bottom: 32px;
    }
    .clickable {
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
  }
  .about-nav {
    align-self: center;
    .about-btn {
      padding: 16px 27px;
      border: 2px solid #333333;
      border-radius: 8px;
      background: none;
      margin: 60px 0px 116px;
      font-weight: 700;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .background-img {
    position: absolute;
    width: 190px;
    height: 190px;
    left: -42px;
    top: 192px;
    z-index: -1;
  }
}
@media only screen and (max-width: 1300px) {
  .other-projects-container {
    h2 {
      margin: 60px 0px 42px;
      font-size: 20px;
    }
    .projects-wrapper {
      .project {
        max-width: unset;
      }
    }
    .about-nav {
      .about-btn {
        margin: 24px 0px 40px;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 1420px) {
  .other-projects-container {
    .background-img {
      display: none;
    }
  }
}
