.bright-smiles-container {
  max-width: 1223px;
  margin: auto;
  margin-top: 175px;
  margin-bottom: 112px;
  padding: 0px 23px;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #333333;
  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 32px;
  }
  img {
    width: 100%;
    height: auto;
  }
  ul {
    margin: 12px 0px 50px 0px;
    li {
      line-height: 200%;
    }
  }
  .objective {
    background: #f4f4f4;
    padding: 38px 60px;
  }
  .analysis {
    margin-top: 70px;
    .analysis-group {
      display: flex;
      flex-wrap: wrap;
      margin-top: 50px;
      gap: 35px;
      .item {
        background: #f4f4f4;
        border-radius: 8px;
        width: 320px;
        min-height: 208px;
        padding: 19px 32px;
        text-align: center;
        .percentage {
          font-weight: 700;
          font-size: 72px;
          font-family: "Poppins";
          margin-top: 42px;
          margin-bottom: 50px;
        }
        .desc {
          max-width: 285px;
          margin: auto;
        }
      }
    }
    .source {
      margin-top: 16px;
      color: #7d7d7d;
      margin-bottom: 50px;
    }
  }
  .nav-buttons {
    display: flex;
    justify-content: start;
    img {
      width: 24px;
      height: 24px;
      margin-bottom: -4px;
      padding-top: 8px;
    }
    a {
      color: #1175b5;
      font-weight: 700;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .bright-smiles-container {
    font-size: 14px;
    margin-top: 118px;
    margin-bottom: 70px;
    h2 {
      font-size: 20px;
      line-height: 28px;
    }
    ul {
      li {
        line-height: 180%;
      }
    }
    .objective {
      padding: 23px 32px;
    }
    .analysis {
      .analysis-group {
        .item {
          min-height: 146px;
          .percentage {
            font-size: 54px;
            margin-top: 35px;
            margin-bottom: 37px;
          }
        }
      }
    }
    .nav-buttons {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
